<template>
  <div class="presenter">
    <ib-carousel style="width: 100%">
      <div style="width: 100%" class="parent">
        <div class="center">
          <!-- <img src="../../assets/ib_logo_schwarz_01_kleiner (1).png" alt="IB Logo" style="max-width: 80%; opacity: 0.8"> -->
          <!-- Album Swiper -->
          <v-container>
            <v-row>
              <v-col cols="6" v-if="$vuetify.breakpoint.mdAndUp" style="padding: 10% 20px; text-shadow: 2px 2px 5px black;">
                <div class="headline">
                  THE NEW SINGLE
                </div>
                <div class="headline" style="margin-bottom: 20px;">
                  🔥 CHAOS 🔥
                </div>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-btn x-large style="width: 50%" @click="navigate('https://indecentbehavior.lnk.to/chaos')">
                        Listen Now
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12" md="6">
                <v-img src="../../assets/ib_chaos_cover_500.jpg" alt="CHAOS" style="width: 70%; margin: auto"></v-img>
              </v-col>
              <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown" style=" text-shadow: 2px 2px 5px black;">
                <div class="headline-mobile">
                  THE NEW SINGLE
                </div>
                <div class="headline-mobile">
                  🔥 CHAOS 🔥
                </div>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-btn x-large @click="navigate('https://indecentbehavior.lnk.to/chaos')">
                        Listen Now
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </ib-carousel>
  </div>
</template>

<script>
import { Carousel } from 'vue-carousel'

export default {
  components: {
    ibCarousel: Carousel
  },
  methods: {
    navigate (target) {
      window.open(target, '_blank')
    }
  }
}
</script>

<style scoped>
.presenter {
  background-image: url('~@/assets/38.jpg');
  min-height: 100vh;
  background-size: cover;
  background-position: bottom;
  padding-top: 70px
}

.headline-mobile {
  font-size: 30px;
  color: white
}

.headline {
  font-size: 46px;
  color: white;
}

.subheading {
  font-size: 20px;
  color: white;
  padding: 20px;
  line-height: 30px
}

.parent {
  height: 100%;
  position: relative;
}

.center {
  padding-top: 10%;
}
</style>
